// FlyingKirt.js
import React, { useEffect, useState } from 'react';
import kirtImage from './assets/kirt.png';

const FlyingKirt = () => {
  const [animationName, setAnimationName] = useState('');
  const [style, setStyle] = useState({});

  useEffect(() => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Generate random start and end positions
    const startX = Math.random() * viewportWidth - viewportWidth / 2;
    const startY = Math.random() * viewportHeight - viewportHeight / 2;
    const startZ = Math.random() * 1000 - 500; // Z position between -500 and 500

    const endX = Math.random() * viewportWidth - viewportWidth / 2;
    const endY = Math.random() * viewportHeight - viewportHeight / 2;
    const endZ = Math.random() * 1000 - 500;

    const duration = 5 + Math.random() * 5; // Duration between 5 and 10 seconds
    const delay = Math.random() * 5; // Delay between 0 and 5 seconds
    const size = 50 + Math.random() * 100; // Size between 50px and 150px

    // Generate a unique animation name
    const animName = `flyby-${Math.random().toString(36).substring(2, 9)}`;
    setAnimationName(animName);

    // Calculate scale based on Z position (simulate depth)
    const startScale = Math.max(0.2, 1 - Math.abs(startZ) / 1000);
    const endScale = Math.max(0.2, 1 - Math.abs(endZ) / 1000);

    // Create keyframes
    const keyframes = `
      @keyframes ${animName} {
        0% {
          transform: translate3d(${startX}px, ${startY}px, ${startZ}px) scale(${startScale});
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        90% {
          opacity: 1;
        }
        100% {
          transform: translate3d(${endX}px, ${endY}px, ${endZ}px) scale(${endScale});
          opacity: 0;
        }
      }
    `;

    // Append the keyframes to the document
    const styleEl = document.createElement('style');
    styleEl.type = 'text/css';
    styleEl.textContent = keyframes;
    document.head.appendChild(styleEl);

    // Set the animation style
    setStyle({
      position: 'absolute',
      top: '40%',
      left: '50%',
      width: `${size}px`,
      height: `${size}px`,
      margin: `-${size / 2}px 0 0 -${size / 2}px`,
      backgroundImage: `url(${kirtImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      animation: `${animName} ${duration}s linear ${delay}s infinite`,
      transformStyle: 'preserve-3d',
      pointerEvents: 'none',
      zIndex: -1,
    });

    // Clean up the style element on unmount
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  return <div style={style}></div>;
};

export default FlyingKirt;
