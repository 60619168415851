// ModalContent.js
import React from 'react';
import iDontKnowImage from './assets/idk.webp';
import whereDoIFindHim from './assets/where.webp';

function ModalContent({ closeModal }) {
  return (
    <div className="modal-content">
      {/* Close Button */}
      <button className="close-button" onClick={closeModal}>
        &times;
      </button>

      {/* Content Block */}
      <div className="content-block">
        <h2>About Jeet</h2>
        <p>
            This is an upcoming game on Sanko Chain that immerses you in the world of cryptocurrency, jeets and fun <br/>(i hope).
        </p>
      </div>

      {/* Additional Content Block */}
      <div className="content-block">
        <img src={iDontKnowImage} alt="Game Screenshot" />
        <p>Not much to say. This is a project that im doing in free time. Will be ready when ready</p>
        <br/>
        <p>Prepare yourself.</p>
      </div>

      {/* Additional Content Block with Features */}
      <div className="content-block">
        <h2>Find me</h2>
        <img src={whereDoIFindHim} alt="Game Screenshot" />
        <ul className="features-list">
          <li>SankoTv: <a href='https://sanko.tv/YuppichBig'>YuppichBig</a></li>
          <li>Twitter: <a href='https://x.com/YuppichBig'>YuppichBig</a></li>
        </ul>
      </div>
    </div>
  );
}

export default ModalContent;
