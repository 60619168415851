// App.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactHowler from 'react-howler';
import { FaPlay, FaInfoCircle, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import ModalContent from './ModalContent';
import FlyingKirt from './FlyingKirt';
import './styles.css';

// Import images and audio
import logo from './assets/logo.webp';
import backgroundMusic from './assets/background-music.mp3';

Modal.setAppElement('#root');

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handlePlay = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
  };

  const toggleMute = (e) => {
    e.stopPropagation(); // Prevent triggering handlePlay
    setIsMuted(!isMuted);
  };

  return (
    <div className="App" onClick={handlePlay}>
      {/* Background Music */}
      {isPlaying && (
        <ReactHowler
          src={backgroundMusic}
          playing={true}
          loop={true}
          volume={0.5}
          mute={isMuted}
        />
      )}

      {/* Mute Button */}
      {isPlaying && (
        <button className="mute-button" onClick={toggleMute}>
          {isMuted ? <FaVolumeMute size={24} /> : <FaVolumeUp size={24} />}
        </button>
      )}

      {/* Render Multiple FlyingKirt Instances */}
      {isPlaying &&
        Array.from({ length: 3 }).map((_, index) => (
          <FlyingKirt key={index} />
        ))}

      {/* Main Content */}
      <div className="main-content">
        {/* Game Title Image */}
        <img
          src={logo}
          alt="Jeet Clicker"
          className="game-title-image game-title"
        />
        {/* Subtitle Text */}
        <div className="game-subtitle">Your Crypto Adventure on Sanko Chain Awaits</div>

        {/* Play Button */}
        <button className="play-button" onClick={() => alert('Coming Soon!')}>
          <FaPlay size={24} /> Coming Soon
        </button>

        {/* Info Button */}
        <button className="info-button" onClick={openModal}>
          <FaInfoCircle size={24} /> Info
        </button>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Game Info"
        className="modal"
        overlayClassName="overlay"
      >
        <ModalContent closeModal={closeModal} />
      </Modal>
    </div>
  );
}

export default App;
